"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isCanada = isCanada;
exports.isUnitedStates = isUnitedStates;
exports.isUnitedStatesAndTerritories = isUnitedStatesAndTerritories;
exports.unitedStatesAndTerritories = void 0;

require("core-js/modules/es.array.includes.js");

var _Countries = _interopRequireDefault(require("../constants/Countries"));

var unitedStatesAndTerritories = ['US', 'PR', 'GU', 'VI', 'MP', 'AS', 'UM'];
exports.unitedStatesAndTerritories = unitedStatesAndTerritories;

function isCanada(countryCode) {
  return _Countries.default[countryCode] === _Countries.default.CA;
}

function isUnitedStates(countryCode) {
  return _Countries.default[countryCode] === _Countries.default.US;
}

function isUnitedStatesAndTerritories(countryCode) {
  return unitedStatesAndTerritories.includes(countryCode);
}