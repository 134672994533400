"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isInternal = exports.getDomain = void 0;

require("core-js/modules/web.url.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/es.array.iterator.js");

require("core-js/modules/web.dom-collections.iterator.js");

var _get = _interopRequireDefault(require("lodash/get"));

/**
 * WARNING WARNING WARNING - This file is copied over from
 * webapp/universal/src/shared/utils/EnvUtils.ts
 *
 * THIS IS MEANT TO BE TEMPORARY
 * EnvUtils should be extracted out to a shared
 * package so that it can be consumed in commerce-utils more easily.
 */
var getDomain = function getDomain() {
  var windowOrUrlString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window;

  if (typeof windowOrUrlString === 'string') {
    var url = new URL(windowOrUrlString);
    return url.hostname;
  }

  return (0, _get.default)(windowOrUrlString, 'Static.SQUARESPACE_CONTEXT.appDomain', (0, _get.default)(windowOrUrlString, 'location.host', ''));
};

exports.getDomain = getDomain;

var isInternal = function isInternal() {
  var windowOrUrlString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window;
  return /\.(sqsp|squarespace)\.net$/.test(getDomain(windowOrUrlString));
};

exports.isInternal = isInternal;