"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isSubscriptionIndefinite = exports.isSubscribableProductTypeForMerchant = exports.getSubscriptionPriceString = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

require("core-js/modules/es.object.freeze.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

var _helpers = require("./i18n/helpers");

var _get = _interopRequireDefault(require("lodash/get"));

var _commerceConstants = require("@sqs/commerce-constants");

var _ProductTypes = _interopRequireDefault(require("@sqs/enums/ProductTypes"));

var _Object$freeze;

/**
 * Indicates whether the provided product type is subscribable for this merchant.
 * @param productType
 * @returns {Boolean}
 */
var isSubscribableProductTypeForMerchant = function isSubscribableProductTypeForMerchant(productType) {
  return productType === _ProductTypes.default.PHYSICAL || productType === _ProductTypes.default.SERVICE;
};

exports.isSubscribableProductTypeForMerchant = isSubscribableProductTypeForMerchant;

var isSubscriptionIndefinite = function isSubscriptionIndefinite(_ref) {
  var numBillingCycles = _ref.numBillingCycles;
  return numBillingCycles === 0;
};

exports.isSubscriptionIndefinite = isSubscriptionIndefinite;
var durations = Object.freeze((_Object$freeze = {}, (0, _defineProperty2.default)(_Object$freeze, _commerceConstants.BillingPeriodUnits.WEEK, 52), (0, _defineProperty2.default)(_Object$freeze, _commerceConstants.BillingPeriodUnits.MONTH, 12), (0, _defineProperty2.default)(_Object$freeze, _commerceConstants.BillingPeriodUnits.YEAR, 1), _Object$freeze));
/**
 * Lodash get path requires (number | string)[] as a path
 * the construction of this path in getSubscriptionPriceString()
 * contains multiple places where a mix of boolean is interspersed
 * into this array. This util casts it to a string.
 */

var stringArr = function stringArr(arr) {
  return arr.map(function (_) {
    return _.toString();
  });
};

var getSubscriptionPriceString = function getSubscriptionPriceString(subscriptionPlan) {
  var _true, _true2, _false, _true3, _true4, _true5;

  var priceString = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var isPaywall = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var _subscriptionPlan$bil = subscriptionPlan.billingPeriod,
      billingPeriodValue = _subscriptionPlan$bil.value,
      billingPeriodUnit = _subscriptionPlan$bil.unit,
      numBillingCycles = subscriptionPlan.numBillingCycles;
  var durationValue = billingPeriodValue * numBillingCycles;
  var durationUnit = billingPeriodUnit;
  var isFiniteSubscription = !isSubscriptionIndefinite(subscriptionPlan); // For a non-paywall finite subscription, the yearly format is applied to
  // the length of the subscription if the billing duration is 52 weeks or 12 months.

  if (!isPaywall && durationValue % durations[billingPeriodUnit] === 0) {
    durationUnit = _commerceConstants.BillingPeriodUnits.YEAR;
    durationValue /= durations[billingPeriodUnit];
  }

  var path = [billingPeriodValue > 1, billingPeriodUnit, isFiniteSubscription];

  if (isFiniteSubscription) {
    path.push(durationUnit);

    if (durationUnit === _commerceConstants.BillingPeriodUnits.YEAR) {
      path.push(durationValue > 1);
    }
  } // This tree is organized as follows. Invalid values are left off the tree.
  //  + billing period magnitude is plural
  //  + billing period unit
  //  + subscription finiteness ([true] means the subscription is finite)
  //  + duration unit
  //  + duration magnitude is plural


  return (0, _get.default)({
    false: (_false = {}, (0, _defineProperty2.default)(_false, _commerceConstants.BillingPeriodUnits.WEEK, {
      false: (0, _helpers.t)('{priceString} every week', {
        priceString: priceString
      }, {
        project: 'commerce.utils'
      }),
      true: (_true = {}, (0, _defineProperty2.default)(_true, _commerceConstants.BillingPeriodUnits.WEEK, (0, _helpers.t)('{priceString} every week for {durationValue} weeks', {
        priceString: priceString,
        durationValue: durationValue
      }, {
        project: 'commerce.utils'
      })), (0, _defineProperty2.default)(_true, _commerceConstants.BillingPeriodUnits.YEAR, {
        false: (0, _helpers.t)('{priceString} every week for 1 year', {
          priceString: priceString
        }, {
          project: 'commerce.utils'
        }),
        true: (0, _helpers.t)('{priceString} every week for {durationValue} years', {
          priceString: priceString,
          durationValue: durationValue
        }, {
          project: 'commerce.utils'
        })
      }), _true)
    }), (0, _defineProperty2.default)(_false, _commerceConstants.BillingPeriodUnits.MONTH, {
      false: (0, _helpers.t)('{priceString} every month', {
        priceString: priceString
      }, {
        project: 'commerce.utils'
      }),
      true: (_true2 = {}, (0, _defineProperty2.default)(_true2, _commerceConstants.BillingPeriodUnits.MONTH, (0, _helpers.t)('{priceString} every month for {durationValue} months', {
        priceString: priceString,
        durationValue: durationValue
      }, {
        project: 'commerce.utils'
      })), (0, _defineProperty2.default)(_true2, _commerceConstants.BillingPeriodUnits.YEAR, {
        false: (0, _helpers.t)('{priceString} every month for 1 year', {
          priceString: priceString
        }, {
          project: 'commerce.utils'
        }),
        true: (0, _helpers.t)('{priceString} every month for {durationValue} years', {
          priceString: priceString,
          durationValue: durationValue
        }, {
          project: 'commerce.utils'
        })
      }), _true2)
    }), (0, _defineProperty2.default)(_false, _commerceConstants.BillingPeriodUnits.YEAR, {
      false: (0, _helpers.t)('{priceString} every year', {
        priceString: priceString
      }, {
        project: 'commerce.utils'
      }),
      true: (0, _helpers.t)('{priceString} every year for {durationValue} years', {
        priceString: priceString,
        durationValue: durationValue
      }, {
        project: 'commerce.utils'
      })
    }), _false),
    true: (_true5 = {}, (0, _defineProperty2.default)(_true5, _commerceConstants.BillingPeriodUnits.WEEK, {
      false: (0, _helpers.t)('{priceString} every {billingPeriodValue} weeks', {
        priceString: priceString,
        billingPeriodValue: billingPeriodValue
      }, {
        project: 'commerce.utils'
      }),
      true: (_true3 = {}, (0, _defineProperty2.default)(_true3, _commerceConstants.BillingPeriodUnits.WEEK, (0, _helpers.t)('{priceString} every {billingPeriodValue} weeks for {durationValue} weeks', {
        priceString: priceString,
        billingPeriodValue: billingPeriodValue,
        durationValue: durationValue
      }, {
        project: 'commerce.utils'
      })), (0, _defineProperty2.default)(_true3, _commerceConstants.BillingPeriodUnits.YEAR, {
        false: (0, _helpers.t)('{priceString} every {billingPeriodValue} weeks for 1 year', {
          priceString: priceString,
          billingPeriodValue: billingPeriodValue
        }, {
          project: 'commerce.utils'
        }),
        true: (0, _helpers.t)('{priceString} every {billingPeriodValue} weeks for {durationValue} years', {
          priceString: priceString,
          billingPeriodValue: billingPeriodValue,
          durationValue: durationValue
        }, {
          project: 'commerce.utils'
        })
      }), _true3)
    }), (0, _defineProperty2.default)(_true5, _commerceConstants.BillingPeriodUnits.MONTH, {
      false: (0, _helpers.t)('{priceString} every {billingPeriodValue} months', {
        priceString: priceString,
        billingPeriodValue: billingPeriodValue
      }, {
        project: 'commerce.utils'
      }),
      true: (_true4 = {}, (0, _defineProperty2.default)(_true4, _commerceConstants.BillingPeriodUnits.MONTH, (0, _helpers.t)('{priceString} every {billingPeriodValue} months for {durationValue} months', {
        priceString: priceString,
        billingPeriodValue: billingPeriodValue,
        durationValue: durationValue
      }, {
        project: 'commerce.utils'
      })), (0, _defineProperty2.default)(_true4, _commerceConstants.BillingPeriodUnits.YEAR, {
        false: (0, _helpers.t)('{priceString} every {billingPeriodValue} months for 1 year', {
          priceString: priceString,
          billingPeriodValue: billingPeriodValue
        }, {
          project: 'commerce.utils'
        }),
        true: (0, _helpers.t)('{priceString} every {billingPeriodValue} months for {durationValue} years', {
          priceString: priceString,
          billingPeriodValue: billingPeriodValue,
          durationValue: durationValue
        }, {
          project: 'commerce.utils'
        })
      }), _true4)
    }), (0, _defineProperty2.default)(_true5, _commerceConstants.BillingPeriodUnits.YEAR, {
      false: (0, _helpers.t)('{priceString} every {billingPeriodValue} years', {
        priceString: priceString,
        billingPeriodValue: billingPeriodValue
      }, {
        project: 'commerce.utils'
      }),
      true: (0, _helpers.t)('{priceString} every {billingPeriodValue} years for {durationValue} years', {
        priceString: priceString,
        billingPeriodValue: billingPeriodValue,
        durationValue: durationValue
      }, {
        project: 'commerce.utils'
      })
    }), _true5)
  }, stringArr(path), (0, _helpers.t)('Unavailable', null, {
    project: 'commerce.utils'
  }));
};

exports.getSubscriptionPriceString = getSubscriptionPriceString;