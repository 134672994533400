import "shared/i18n/configurations/commerce-currency.js";import { t } from 'shared/i18n';
import { fromCents } from '@sqs/commerce-currency';
import StoreCurrencyManager from 'shared/utils/commerce/StoreCurrencyManager';
/**
 * ShoppingCart Singleton model.
 *
 * @singleton
 * @module squarespace-models-shopping-cart
 */
YUI.add('squarespace-models-shopping-cart', function (Y) {

  Y.namespace('Squarespace.Models');
  Y.namespace('Squarespace.Singletons');

  var ShoppingCart =
  Y.Squarespace.Models.ShoppingCart = Y.Base.create('shoppingCart', Y.Model,
  [],
  {
    initializer: function () {

      /**
       * Item was successfully added
       * @event item-added
      */
      this.publish('item-added', { emitFacade: true });

      /**
       * Cart has been loaded
       * @event loaded
      */
      this.publish('loaded', { emitFacade: true });

      /**
       * After modification - fired when starting to recalculate pricing
       * @event recalculate-start
      */
      this.publish('recalculate-start', { emitFacade: true });

      /**
       * After modification - new prices and totals are ready
       * @event recalculate-end
      */
      this.publish('recalculate-end', { emitFacade: true });

      this._readInProgress = false;
      this._isRecalculating = false;

      this.on('recalculate-start', function () {
        this._isRecalculating = true;
      }, this);

      this.on('recalculate-end', function () {
        this._isRecalculating = false;
      }, this);

    },

    toJSON: function () {
      var attrs = ShoppingCart.superclass.toJSON.call(this);
      var created = attrs.created;

      if (!Y.Lang.isNull(created)) {
        attrs.created = created.getTime();
      }

      return attrs;
    },

    sync: function (action, options, callback) {
      switch (action) {
        case 'create':
          break;

        case 'delete':
          break;

        case 'read':
          this._read(options, callback);
          break;

        case 'update':
          break;}

    },

    load: function (options, callback, ctx) {
      // allow callback as only arg
      if (typeof options === 'function') {
        callback = options;
        options = {};
      }

      if (this._readinProgress) {
        this.after('load', callback, ctx);
        return;
      }

      Y.Model.prototype.load.call(this, options, callback ? Y.bind(callback, ctx || this) : null);
    },

    isRecalculating: function () {
      return this._isRecalculating;
    },

    hasEntry: function (itemId, chosenVariant) {
      return Y.Array.reduce(this.get('entries'), false, function (previous, entry) {

        if (Y.Lang.isValue(chosenVariant)) {
          return previous || entry.itemId === itemId && chosenVariant.sku === entry.chosenVariant.sku;
        }

        return previous || entry.itemId === itemId;


      });
    },

    addEntry: function (itemId, chosenVariant, quantity, additionalFields, callback, ctx) {

      Y.Data.post({
        url: '/api/commerce/shopping-cart/entries',
        headers: { 'Content-Type': 'application/json' },
        data: Y.JSON.stringify({
          itemId: itemId,
          sku: Y.Lang.isValue(chosenVariant) ? chosenVariant.sku : null,
          quantity: quantity,
          additionalFields: Y.JSON.stringify(additionalFields) }),

        success: function (data) {
          Y.Squarespace.CommerceAnalytics.itemAdded(data);

          this.setAttrs(data.shoppingCart);

          // notify that an item successfully added!
          this.fire('item-added');

          if (Y.Lang.isFunction(callback)) {
            callback.call(ctx || this, null, data.newlyAdded);
          }
        },
        failure: function (data) {

          // if it's a crumb failure, tell them to reload the page. [SIX-9885]
          if (data.crumbFail) {
            if (Y.Squarespace.Utils.areCookiesEnabled()) {
              data.message = t("Your session has expired. Please reload the page and try again.");


            } else {
              data.message = t("Please enable cookies in your browser, reload the page, and try again.");






            }
          }

          if (data.status === 429) {
            // request was blocked by backend rate limit.
            data.message = t("Please try again in a few seconds.");






          }

          if (Y.Lang.isFunction(callback)) {
            callback.call(ctx || this, data.message, null);
          }
        } },
      this);
    },

    updateFormSubmission: function (entry, additionalFields, callback, ctx) {
      var chosenVariant = entry.chosenVariant;
      this.fire('recalculate-start');

      Y.Data.put({
        headers: { 'Content-Type': 'application/json' },
        url: Y.Lang.sub('/api/commerce/shopping-cart/entries/{entryId}/additionalFields', {
          entryId: entry.id }),

        data: Y.JSON.stringify({
          itemId: entry.itemId,
          sku: Y.Lang.isValue(chosenVariant) ? chosenVariant.sku : null,
          additionalFields: Y.JSON.stringify(additionalFields) }),

        success: function (data) {
          this.fire('recalculate-end');
          this.setAttrs(data.shoppingCart);

          if (Y.Lang.isFunction(callback)) {
            callback.call(ctx || this, null, data.updatedEntry);
          }
        },
        failure: function (data) {
          this.fire('recalculate-end');
          if (Y.Lang.isFunction(callback)) {
            callback.call(ctx || this, data.message, null);
          }
        } },
      this);
    },

    totalForItem: function (itemId, variant) {
      var hasVariant = Y.Lang.isValue(variant);

      return Y.Array.reduce(this.get('entries'), 0, function (prev, entry) {
        if (entry.itemId === itemId) {
          if (!hasVariant || hasVariant && entry.chosenVariant.sku === variant.sku) {
            return prev + entry.quantity;
          }
        }

        return prev;
      });
    },

    /**
     * Sync methods
     */
    _read: function (options, callback) {
      this._readInProgress = true;

      this.fire('loading');

      Y.Data.get({
        url: '/api/commerce/shopping-cart',
        data: {
          // WTF. Why do booleans get converted into int 0/1 ?!
          mock: options.mock ? 'true' : 'false',
          // include timecode to prevent caching of response due to request parameters not changing
          timecode: Date.now(),
          calculateSubtotal: options.calculateSubtotal ? 'true' : 'false' },

        success: function (data) {
          this.fire('loaded');

          this._readInProgress = false;
          callback(null, data);
        },
        failure: function (data) {
          this.fire('loaded');

          this._readInProgress = false;
          callback(data.message, null);
        } },
      this);
    } },

  {
    ATTRS: {
      /**
       * Stored values
       */
      id: {
        validator: Y.Lang.isString },

      websiteId: {
        validator: Y.Lang.isString },

      orderId: {
        validator: Y.Lang.isString },

      created: {
        getter: function (value) {
          if (!Y.Lang.isValue(value)) {
            return null;
          }
          return new Date(value);

        },
        setter: function (value) {
          if (Y.Lang.isDate(value)) {
            return value.getTime();
          }
          return value;

        },
        validator: Y.Lang.isNumber },

      isPurchased: {
        value: false,
        validator: Y.Lang.isBoolean },

      entries: {
        value: [],
        validator: Y.Lang.isArray },

      shippingOptions: {
        value: [],
        validator: Y.Lang.isArray },

      selectedShippingOption: {
        validator: Y.Lang.isObject },

      shippingLocation: {
        validator: Y.Lang.isObject },

      applicableTaxRules: {
        value: [],
        validator: Y.Lang.isArray },

      coupons: {
        value: [],
        validator: Y.Lang.isArray },


      /**
       * Transient values
       */
      validCoupons: {
        value: [],
        validator: Y.Lang.isArray },

      invalidCoupons: {
        value: [],
        validator: Y.Lang.isArray },

      subtotalCents: {
        validator: Y.Lang.isNumber,
        value: 0 },

      subtotalAmount: {
        getter: function () {
          var storeCurrency = StoreCurrencyManager.getStoreCurrency();
          return fromCents(this.get('subtotalCents'), storeCurrency);
        } },

      taxCents: {
        validator: Y.Lang.isNumber,
        value: 0 },

      shippingCostCents: {
        validator: Y.Lang.isNumber,
        value: 0 },

      discountCents: {
        validator: Y.Lang.isNumber,
        value: 0 },

      grandTotalCents: {
        validator: Y.Lang.isNumber,
        value: 0 },

      totalQuantity: {
        validator: Y.Lang.isNumber,
        value: 0 },

      hasDigital: {
        validator: Y.Lang.isBoolean,
        value: false },

      purelyDigital: {
        validator: Y.Lang.isBoolean,
        value: false },

      requiresShipping: {
        validator: Y.Lang.isBoolean,
        value: false } } });





  // init the singleton
  Y.Squarespace.Singletons.ShoppingCart = new ShoppingCart();

}, '1.0', {
  requires: [
  'base',
  'model',
  'squarespace-commerce-analytics',
  'squarespace-commerce-utils',
  'squarespace-util'] });