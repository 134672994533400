import RecordType from '@sqs/enums/RecordType';

/**
 * @module squarespace-commerce-analytics
 */
YUI.add('squarespace-commerce-analytics', function (Y) {

  /**
   * Commerce Tracking API
   * Used for merchants' google analytics.
   * Squarespace internally has the same events instrumented but in the backend.
   *
   * @class CommerceTrack
   * @extends Base
   * @private
   */

  var CommerceTrack = new Y.Base.create('commerceTrack', Y.Base, [], {
    _reportSafelyForCart: function(eventName, cart) {
      /** Simplify the cart sent over the wire to just a few key fields **/
      if (Y.Lang.isValue(cart)) {
        this._reportSafely(eventName, {
          cartId: cart.get('id'),
          websiteId: cart.get('websiteId')
        });
      } else {
        this._reportSafely(eventName);
      }
    },

    _reportSafely: function(eventName, payload) {
      try {
        Y.Squarespace.Analytics.trackInternal(eventName, payload);
      } catch (e) {
        console.error(e);
      }
    },

    itemViewed: function (item) {
      this.fire('commerce-item-viewed', item);
    },

    itemAdded: function (cart) {
      this.fire('commerce-item-added', cart);
    },

    checkoutConfirmed: function (order) {
      var simpleOrder = {
        orderId: order.id,
        cartId: order.purchasedCartId,
        testMode: order.testMode,
        websiteId: order.websiteId
      };
      this._reportSafely('commerce_checkout_order_confirmed', simpleOrder);
      this.fire('commerce-checkout-confirmed', order);
    }

  }, {
    ATTRS: {}
  });

  Y.namespace('Squarespace');

  /**
   * Public instance of Commerce tracking API
   * @class CommerceAnalytics
   * @namespace Squarespace
   * @static
   */
  Y.Squarespace.CommerceAnalytics = new CommerceTrack();

  /**
   * Base Commerce Analytics Class
   * Subclass this for each tracking system / api you need to support.
   */
  var CommerceAnalyticsBase = Y.Base.create('commerceAnalytics', Y.Base, [], {
    initializer: function () {
      this._events = [
        Y.Squarespace.CommerceAnalytics.on('commerce-item-viewed',
          this._getProtectedTracker('onItemViewed'), this),
        Y.Squarespace.CommerceAnalytics.on('commerce-item-added',
          this._getProtectedTracker('onItemAdded'), this),
        Y.Squarespace.CommerceAnalytics.on('commerce-checkout-confirmed',
          this._getProtectedTracker('onCheckoutConfirmed'), this)
      ];
    },

    destructor: function () {
      Y.Array.invoke(this._events, 'detach');
    },

    _getProtectedTracker: function (type) {
      return function (data) {
        try {
          this[type].call(this, data);
        } catch (err) {

        }
      };
    },

    onItemViewed: function (item) {},

    onItemAdded: function (cart) {},

    onCheckoutConfirmed: function (order) {}

  }, {
    ATTRS: {}
  });


  /**
   * Google eCommerce Analytics
   */
  var CommerceAnalyticsGoogle = Y.Base.create('commerceAnalyticsGoogle', CommerceAnalyticsBase, [], {
    onCheckoutConfirmed: function (order) {

      function getMoneyValue(moneyJson) {
        // legacy money format: this will be removed soon
        if (moneyJson.decimalValue !== undefined) {
          return moneyJson.decimalValue;
        }
        // sqsp money format
        return moneyJson.value;
      }

      function getItems() {
        // get items in purchase formatted for gtag.js
        var result = [];

        Y.each(order.items, function (item) {
          result.push( {
            'id': item.sku, // SKU/code
            'name': item.productName,
            'quantity': item.quantity,
            'price': getMoneyValue(item.unitPrice) // Unit price
          });
        });
        return result;
      }

      if (window.gtag) {
        window.gtag('event', 'purchase', {
          'transaction_id': order.id, // Transaction ID.
          'value': getMoneyValue(order.grandTotal), // Order grand total
          'shipping': getMoneyValue(order.shippingTotal), // Shipping
          'tax': getMoneyValue(order.taxTotal), // Tax
          'items': getItems() // Purchased items
        });
      }
    }
  }, {
    ATTRS: {}
  });

  /**
    This shit is pretty convoluted; it uses inheritance. By instantiating an instance of
    CommerceAnalyticsGoogle, the inherited constructor (in CommerceAnalyticsBase) causes events
    to be subscribed to. The general handler invoke each of the subclass' handling methods.
  **/
  new CommerceAnalyticsGoogle();

  var potentialItemRecordType = Y.Object.getValue(Static, ['SQUARESPACE_CONTEXT',
    'item', 'recordType']);

  if (potentialItemRecordType === RecordType.STORE_ITEM) {
    Y.Squarespace.CommerceAnalytics.itemViewed(Static.SQUARESPACE_CONTEXT.item);
  }

}, '1.0', { requires: [
  'array-invoke',
  'base',
  'event-custom',
  'node',
  'squarespace-util'
] });
