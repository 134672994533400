"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _cookieCutter = _interopRequireDefault(require("@sqs/cookie-cutter"));

var SITE_USER_INFO_COOKIE_NAME = 'SiteUserInfo';

var parseCookie = function parseCookie() {
  var rawCookieValue = _cookieCutter.default.get(SITE_USER_INFO_COOKIE_NAME);

  if (!rawCookieValue) {
    return null;
  }

  try {
    return JSON.parse(rawCookieValue);
  } catch (e) {
    console.error('Could not parse user info cookie value', e);
    return null;
  }
};
/**
 * Provides basic information of current detected site user while abstracting away the underlying resolution logics.
 */


var SiteUserAccountInfoAccessor = {
  /**
   * Whether current site visitor has an authenticated login session (could be stale).
   */
  isAuthenticated: function isAuthenticated() {
    var userInfo = parseCookie();
    return !!(userInfo !== null && userInfo !== void 0 && userInfo.authenticated);
  },

  /**
   * If the current site visitor has an active/stale login session, returns their SiteUser id.
   */
  getSiteUserId: function getSiteUserId() {
    var userInfo = parseCookie();
    return userInfo === null || userInfo === void 0 ? void 0 : userInfo.siteUserId;
  }
};
var _default = SiteUserAccountInfoAccessor;
exports.default = _default;
module.exports = exports.default;