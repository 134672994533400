"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _helpers = require("../i18n/helpers");

var _default = (0, _helpers.getCountries)();

exports.default = _default;
module.exports = exports.default;