/*
 * This file was generated by the `create-manifests` gulp task.
 * Run `npm run gulp create-manifests` to regenerate this file.
 */
import 'src/main/webapp/universal/scripts-v6/product-quick-view.js';
import 'src/main/webapp/universal/scripts-v6/widgets/products/quick-view.js';
import 'src/main/webapp/universal/scripts-v6/cart-utils.js';
import 'src/main/webapp/universal/scripts-v6/async-form.js';
import 'src/main/webapp/universal/scripts-v6/ss-widget.js';
import 'src/main/webapp/universal/scripts-v6/debugger.js';
import 'src/main/webapp/universal/scripts-v6/template-helpers.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/handlebars-base/handlebars-base.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/substitute/substitute.js';
import 'src/main/webapp/universal/scripts-v6/ui-templates.js';
import 'src/main/webapp/universal/scripts-v6/modal-lightbox.js';
import 'src/main/webapp/universal/scripts-v6/models/shopping-cart.js';
import 'src/main/webapp/universal/node_modules/@sqs/yui/model/model.js';
import 'src/main/webapp/universal/scripts-v6/commerce-analytics.js';
import 'src/main/webapp/universal/scripts-v6/system-collections/products/products-2.0-item.js';
import 'src/main/webapp/universal/scripts-v6/image-zoom.js';