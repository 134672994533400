export var VISITOR_IDENTIFIER_COOKIE = 'crumb';
/**
 * Unique per visitor session per website.
 * @see https://support.squarespace.com/hc/en-us/articles/360001264507-The-cookies-Squarespace-uses#toc-functional-and-required-cookies
 */

export var CRUMB_COOKIE = 'crumb';
/**
 * This cookie is a temp way to uniquely identify site visitors in addition to Crumb Cookie. It's stable across multiple
 * sessions on the same browser for a given user on a given website.
 * @see https://support.squarespace.com/hc/en-us/articles/360001264507-The-cookies-Squarespace-uses#toc-analytics-and-performance-cookies
 */

export var CENSUS_COOKIE = 'ss_cvr';