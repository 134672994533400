import 'shared/i18n/bootstrap';

import CookieCutter from '@sqs/cookie-cutter';
import { t } from 'shared/i18n';
import substituteString from 'shared/utils/formatting/substituteString';
import { trackProductQuickView } from 'shared/utils/census/CensusUtils';

/**
 * @module squarespace-widgets-products-quick-view
 */

YUI.add('squarespace-widgets-products-quick-view', function (Y) {

  /**
   * Product Quick View Widget
   *
   * @class QuickView
   * @constructor
   * @namespace Squarespace.Widgets.Products
   * @extends Squarespace.SSWidget
   */

  Y.namespace('Squarespace.Widgets.Products');

  var QuickView =
  Y.Squarespace.Widgets.Products.QuickView =
  Y.Base.create('quickView', Y.Squarespace.SSWidget, [], {

    initializer: function () {

      this.set('lightbox', new Y.Squarespace.Widgets.ModalLightbox({
        content: this.get('boundingBox'),
        render: Y.one('body'),
        className: QuickView.CSS_LIGHTBOX_PREFIX }));


      this.get('lightbox').get('boundingBox').
      prepend(this.get('closeButton'));

      this.get('boundingBox').
      append(this.get('prevButton')).
      append(this.get('nextButton'));

    },

    bindUI: function () {

      QuickView.superclass.bindUI.call(this);

      this.after(['currentIdChange', 'currentGroupChange'], function () {
        this._destroyProductItemViewInstance();
        this.syncUI();
      });

      this._registerEvent(this.get('lightbox').on('open', function () {
        this.syncUI();
      }, this));

      this._registerEvent(this.get('lightbox').on('close', function () {
        this._destroyProductItemViewInstance();
        this.set('isLightboxOpen', false);
      }, this));

      this._bindUIControls();
      this._bindKeyboardControls();

      if (window.SQUARESPACE_LOGIN && window.SQUARESPACE_LOGIN.isLoggedIn()) {
        Y.on('beforeunload', function () {
          this.destroy(true);
          this.get('lightbox').destroy(true);
        }, this);
      }

    },

    syncUI: function () {

      QuickView.superclass.syncUI.call(this);

      this._getData();

      // don't show prev/next controls if there's no prev/next item
      this.get('nextButton').toggleClass(QuickView.CSS_HIDDEN, !this._getNextId());
      this.get('prevButton').toggleClass(QuickView.CSS_HIDDEN, !this._getPrevId());
      trackProductQuickView(this.get('currentId'));
    },

    destructor: function () {
      this._destroyProductItemViewInstance();
      this.get('lightbox').close();
    },

    _bindUIControls: function () {

      var bb = this.get('boundingBox');

      this._registerEvent(bb.delegate('click', function (e) {
        this._setId(this._getNextId());
        e.halt(true);
      }, '.' + QuickView.CSS_NEXT_BUTTON, this));

      this._registerEvent(bb.delegate('click', function (e) {
        this._setId(this._getPrevId());
        e.halt(true);
      }, '.' + QuickView.CSS_PREV_BUTTON, this));

      this._registerEvent(bb.delegate('click', function (e) {
        this.get('lightbox').close();
        e.stopPropagation();
      }, '.' + QuickView.CSS_CLOSE_BUTTON, this));

      // click "anywhere" to close
      this._registerEvent(Y.one('body').on('click', function (e) {
        if (this.get('isLightboxOpen') && !bb.contains(e.target) && !this._isProductForm(e.target)) {
          this.get('lightbox').close();
        }
        e.stopPropagation();
      }, this));

    },

    _bindKeyboardControls: function () {

      // right arrow
      this._registerEvent(Y.on('key', function () {
        if (this.get('isLightboxOpen')) {
          this._setId(this._getNextId());
        }
      }, Y.config.win, 'down:39', this));

      // left arrow
      this._registerEvent(Y.on('key', function () {
        if (this.get('isLightboxOpen')) {
          this._setId(this._getPrevId());
        }
      }, Y.config.win, 'down:37', this));

    },

    _getData: function () {

      Y.Data.get({
        url: '/api/rendering/product-quick-view/' + this.get('currentId'),
        success: function (response) {
          this._renderModal(response.html);
        },
        failure: function (response) {
          this._renderError(response);
        } },
      this);

    },

    _renderModal: function (responseHtml) {

      this.get('contentBox').
      empty().
      insert(responseHtml);

      this.set('productsItemViewInstance', new Y.Squarespace.SystemCollections.Products.ItemV2({
        host: this.get('contentBox') }));


      if (!this.get('isLightboxOpen')) {
        this.open();
      } else {
        this.get('lightbox').syncUI();
      }

      this._executeScripts();
      this._initializeCheckoutArea();

    },

    open: function () {
      this.get('lightbox').open();
      this.set('isLightboxOpen', true);

      if (Y.one('.sqs-style-mode-active')) {
        Y.Squarespace.EscManager.removeTarget(this.get('lightbox'));
      }
    },

    /**
     * Execute any scripts that are in the block template.
     *
     * @method _executeScripts
     */
    _executeScripts: function () {

      var cb = this.get('contentBox');

      cb.all('script').each(function (existingScriptNode) {
        var newScript = document.createElement('script');
        newScript.type = 'text/javascript';
        if (existingScriptNode.getAttribute('src')) {
          newScript.src = existingScriptNode.getAttribute('src');
        } else {
          newScript.innerHTML = existingScriptNode.get('innerHTML');
        }

        existingScriptNode.remove();

        cb.append(newScript);
      }, this);

    },

    /**
     * Initialize checkout functionality for the newly-rendered item...
     * Method calls and logic are cherry-picked from Commerce.initializeCommerce.
     *
     * @method _initializeCheckoutArea
     */
    _initializeCheckoutArea: function () {

      var cb = this.get('contentBox');
      if (Y.config.win.document.getElementsByClassName('product-variants').length) {
        require.ensure([], function (require) {
          var productVariants = require('shared/productVariants');
          productVariants.initializeVariantDropdowns(Y.config.win.document, cb.getDOMNode());
        }, null, 'commerce-product-variants');
      }

      Y.Squarespace.CartUtils.initializeAddToCartButtons();

      // if the CART cookie is defined we need to load first, before allowing
      // button interactions
      if (CookieCutter.get('hasCart') === 'true' || Y.Lang.isValue(CookieCutter.get('CART'))) {
        Y.Squarespace.Singletons.ShoppingCart.load(function () {
          Y.all('.sqs-add-to-cart-button-wrapper').setStyle('visibility', 'visible');
        }, this);
      } else {
        Y.all('.sqs-add-to-cart-button-wrapper').setStyle('visibility', 'visible');
      }

    },

    _renderError: function (response) {

      var defaultErrorMessage = t("There was an issue loading the quick view of this product. Please see the product's page for full product details.");




      new Y.Squarespace.Widgets.Alert({
        'strings.title': t("Error: {message}", {
          message: response && response.title ? response.title : '' }),



        'strings.message': response && response.error || defaultErrorMessage });


    },

    _getNextId: function () {

      var idArray = this.get('products')[this.get('currentGroup')];
      var nextIdIndex = idArray.indexOf(this.get('currentId')) + 1;
      var nextId = idArray[nextIdIndex];

      return nextId || false;

    },

    _getPrevId: function () {

      var idArray = this.get('products')[this.get('currentGroup')];
      var prevIdIndex = idArray.indexOf(this.get('currentId')) - 1;
      var prevId = idArray[prevIdIndex];

      return prevId || false;

    },

    /**
     * Set the currentId of the lightbox. If we've reached the last product
     * in the group and are trying to move forward (or first product/backwards),
     * the id will be false, in which case no data will be requested and nothing
     * will happen.
     *
     * @method _setId
     * @param  {String} id a product's item id
     */
    _setId: function (id) {

      if (id) {
        this.set('currentId', id);
      }

    },

    _destroyProductItemViewInstance: function () {
      if (this.get('productsItemViewInstance')) {
        this.get('productsItemViewInstance').destroy(true);
      }
    },

    /**
     * Determines whether or not a target is, is within, or contains a product form.
     * For use with the body click event handler that deals with whether or not to close the quick view modal.
     * We don't want interactions with product forms to close the quick view modal beneath.
     *
     * @method _isProductForm
     * @param  {[type]} target The click event target
     * @return {Boolean} Whether or not the target is, is within, or contains a product form.
     */
    _isProductForm: function (target) {
      var className = Y.Squarespace.Widgets.AsyncForm.CSS_PREFIX;
      return target.ancestor('.' + className) ||
      target.hasClass('lightbox-close') && target.siblings('.' + className) ||
      target.one('.' + className) ||
      target.hasClass(className);
    } },

  {
    CSS_PREFIX: 'sqs-product-quick-view',
    CSS_LIGHTBOX_PREFIX: 'sqs-product-quick-view-lightbox',
    CSS_NEXT_BUTTON: 'sqs-product-quick-view-lightbox-next-button',
    CSS_PREV_BUTTON: 'sqs-product-quick-view-lightbox-prev-button',
    CSS_CLOSE_BUTTON: 'sqs-product-quick-view-lightbox-close-button',
    CSS_HIDDEN: 'sqs-product-quick-view-lightbox-element-hidden',
    ATTRS: {
      currentId: {
        value: null },

      currentGroup: {
        value: null },

      products: {
        value: {} },

      lightbox: {
        value: null },

      prevButton: {
        valueFn: function () {
          return Y.Node.create(substituteString(
          '<button class="{sub1}" aria-label="' + t("Previous item") +

          '"><svg viewBox="0 0 23 48"><g class="svg-icon">' +
          '<polyline fill="none" stroke-miterlimit="10" points="21.5,1.3 2.6,23.4 21.5,45.7 "/></g></svg></button>',
          { sub1: QuickView.CSS_PREV_BUTTON }));

        } },

      nextButton: {
        valueFn: function () {
          return Y.Node.create(substituteString(
          '<button class="{sub1}" aria-label="' + t("Next item") +

          '"><svg viewBox="0 0 23 48">' +
          '<g class="svg-icon"><polyline fill="none" stroke-miterlimit="10" points="1.5,45.7 20.4,23.5 1.5,1.3 "/>' +
          '</g></svg></button>',
          { sub1: QuickView.CSS_NEXT_BUTTON }));

        } },

      closeButton: {
        valueFn: function () {
          return Y.Node.create(substituteString(
          '<button class="{sub1}" aria-label="' + t("Close quick view") +

          '"><svg viewBox="0 0 16 16"><g class="svg-icon">' +
          '<line fill="none" stroke-miterlimit="10" x1="1.4" y1="1.4" x2="14.4" y2="14.4"/>' +
          '<line fill="none" stroke-miterlimit="10" x1="1.4" y1="14.4" x2="14.4" y2="1.4"/></g></svg></button>',
          { sub1: QuickView.CSS_CLOSE_BUTTON }));

        } },

      isLightboxOpen: {
        value: false } } });




}, '1.0', {
  requires: [
  'squarespace-beforeunload',
  'squarespace-cart-utils',
  'squarespace-escmanager',
  'squarespace-image-loader',
  'squarespace-modal-lightbox',
  'squarespace-models-shopping-cart',
  'squarespace-products-collection-item-v2',
  'squarespace-ss-widget',
  'squarespace-widgets-alert'] });