import "shared/i18n/configurations/commerce-utils.js"; /* eslint-disable camelcase */
import { t } from 'shared/i18n';
import * as trackEvent from 'shared/utils/commerce/trackEvent';
import BlockType from '@sqs/enums/BlockType';
import ProductTypes from '@sqs/enums/ProductTypes';
import ErrorReporter from '@sqs/error-reporter';
import StoreCurrencyManager from 'shared/utils/commerce/StoreCurrencyManager.js';
import PageTypes from '@sqs/enums/PageTypes';
import { SiteVisitorTracking } from '@sqs/commerce-utils';
import Flag from '@sqs/enums/Flag';

/*sqs-codemod-ignore*/
/**
 * Util methods for cart functionality.
 *
 * @module squarespace-cart-utils
 */
YUI.add('squarespace-cart-utils', function (Y) {

  var additionalFieldsModalLightbox;
  var additionalFieldsFormTemplate;
  /**
   * @class CartUtils
   * @namespace Squarespace.CartUtils
   */
  Y.namespace('Squarespace.CartUtils');

  // BetaFeatureUtils does not provide the correct value on 7.0 PDPs
  // so we use the website context to determine whether or not a feature flag is enabled
  function isFeatureEnabled(feature) {
    return Static.SQUARESPACE_CONTEXT.betaFeatureFlags.includes(feature);
  }

  Y.Squarespace.CartUtils = {

    /**
     * Sets up all of the "Add to Cart"-type buttons on a page.
     */
    initializeAddToCartButtons: function () {
      Y.all('.sqs-add-to-cart-button').each(function (btn) {
        switch (this._getProductTypeFromPurchaseButton(btn)) {
          case ProductTypes.PHYSICAL:
          case ProductTypes.DIGITAL:
          case ProductTypes.SERVICE:
            this._initializeAddToCartButton(btn);
            break;

          case ProductTypes.GIFT_CARD:
            this._initializePurchaseGiftCardButton(btn);
            break;

          default:
            if (__DEV__) {
              console.warn('ProductType not found.');
            }
            break;}


        if (btn.hasClass('use-form') && !additionalFieldsFormTemplate) {
          Y.Squarespace.CartUtils._getAdditionalFieldsFormTemplateSchema(function (template) {
            additionalFieldsFormTemplate = template;
          }, this);
        }
      }, this);
    },

    /**
     * Initializes a button to begin the standard checkout flow.
     *
     * @param {Y_Node} btn The button.
     * @private
     */
    _initializeAddToCartButton: function (btn) {
      var expressCheckout = Y.Squarespace.Commerce.isExpressCheckout();
      var isSubscriptionProduct = btn.getAttribute('data-is-subscription') === 'true';

      var labelOverride;
      if (btn.getAttribute('data-use-custom-label') === 'false') {
        if (isSubscriptionProduct) {
          labelOverride = t("Subscribe");


        } else if (expressCheckout) {
          labelOverride = t("Purchase");


        }
      }
      this._initializeButton(btn, labelOverride);

      var checkoutRedirectHandler = isSubscriptionProduct ?
      Y.Squarespace.CartUtils._goToSubscriptionCheckout :
      Y.Squarespace.CartUtils._goToCheckoutOrAddToCart;
      btn.on('click', Y.Squarespace.CartUtils._addCartEntry, this, checkoutRedirectHandler);
      btn.on('keydown', function (e) {
        // Standard button behavior (which we have to restore here since
        // the widget is not really a button but a div or a span)
        // is to react to Enter (keyCode == 13) and Space (keyCode == 32) presses.
        if (e.keyCode === 13 || e.keyCode === 32) {
          this._addCartEntry(e, checkoutRedirectHandler);
        }
      }.bind(this));
    },

    /**
     * Initializes a button to begin the gift card checkout flow.
     *
     * @param {Y_Node} btn The button.
     * @private
     */
    _initializePurchaseGiftCardButton: function (btn) {
      if (btn.getAttribute('data-use-custom-label') === 'false') {
        this._initializeButton(btn, t("Purchase"));


      } else {
        this._initializeButton(btn);
      }

      btn.on('click', Y.Squarespace.CartUtils._purchaseGiftCard, this);
      btn.on('keypress', function (e) {
        // Standard button behavior (which we have to restore here since
        // the widget is not really a button but a div or a span)
        // is to react to Enter (keyCode == 13) and Space (keyCode == 32) presses.
        if (e.keyCode === 13 || e.keyCode === 32) {
          this._purchaseGiftCard(e);
        }
      }.bind(this));
    },

    /**
     * Initializes a button with animation and optionally overrides the default label
     *
     * @param {Y_Node} btn The button.
     * @param {String} label - Pass in to override the default label
     * @private
     */
    _initializeButton: function (btn, label) {
      var btnInner = btn.one('.sqs-add-to-cart-button-inner');
      btnInner.plug(Y.Squarespace.Animations.Scalable, { duration: 0.2 });

      if (label) {
        btnInner.setContent(label);
        btn.setData('original-label', label);
      }
    },

    /**
     * Gets the type of product an "Add to Cart"-style button purchases.
     *
     * @param {Y_Node} btn The button.
     * @returns {ProductTypes}
     * @private
     */
    _getProductTypeFromPurchaseButton: function (btn) {
      return Number(btn.getAttribute('data-product-type')) || ProductTypes.UNKNOWN;
    },

    /**
     * Sets the width of add to cart buttons so they don't jump around during add-to-cart flow.
     *
     * @private
     */
    _setButtonWidth: function (btn) {
      var buttonWidth = Math.ceil(btn.getDOMNode().getBoundingClientRect().width);
      btn.get('parentNode').setStyles({
        width: buttonWidth + 'px',
        minWidth: '11em' });

      btn.setStyle('display', 'block');
    },

    /**
     * Opens an alert with an error message.
     *
     * @param error the error message
     * @private
     */
    _showError: function (error) {
      var title = Y.Squarespace.Commerce.isExpressCheckout() ? t("Unable to Purchase Item") : t("Unable to Add Item");





      new Y.Squarespace.Widgets.Alert({
        'strings.title': title,
        'strings.message': error });

    },

    /**
     * Opens an alert dialog with the given error message
     *
     * @param error The error message
     * @private
     */
    _showGiftCardError: function (error) {
      var title = t("Unable to purchase Gift Card");


      new Y.Squarespace.Widgets.Alert({
        'strings.title': title,
        'strings.message': error });

    },

    /**
     * Generates an error message due to invalid options selection.
     *
     * @param unselectedOptions a non-empty array of unselected options
     * @returns {String}
     * @private
     */
    _generateErrorString: function (unselectedOptions) {
      var unselectedLength = unselectedOptions.length;

      if (unselectedLength === 1) {
        return t("Please select the {option} option.", {
          option: unselectedOptions[0] });



      } else if (unselectedLength === 2) {
        return t("Please select the {option1} and {option2} options.", {
          option1: unselectedOptions[0],
          option2: unselectedOptions[1] });



      }
      return t("Please select the {optionsList} and {lastOption} options.", {
        optionsList: unselectedOptions.slice(0, -1).join(', '),
        lastOption: unselectedOptions[unselectedOptions.length - 1] });




    },

    /**
     * Adds a new entry to the cart.
     *
     * @param {Event} the click event
     * @private
     */
    _addCartEntry: function (e, _checkoutRedirectHandler) {
      var btn = e.currentTarget;
      var btnWrapper = btn.get('parentNode');

      if (btnWrapper.hasClass('cart-added')) {
        return;
      }

      var itemId = btn.getAttribute('data-item-id');
      var variant,
      qty;
      var btnParent = btn.get('parentNode');

      /*
       * Get variant.
       */
      var productVariantsEl = Y.one(btnParent.siblings('.product-variants').item(0));
      if (Y.Lang.isValue(productVariantsEl)) {
        var unselectedOptions = JSON.parse(productVariantsEl.getAttribute('data-unselected-options'));
        var unselectedLength = unselectedOptions.length;

        // incomplete
        if (unselectedLength > 0) {
          this._showError(this._generateErrorString(unselectedOptions));
          return;
        }

        var selectedVariantDataAttr = productVariantsEl.getAttribute('data-selected-variant');
        var variantInStockDataAttr = productVariantsEl.getAttribute('data-variant-in-stock');
        var selectedVariant = selectedVariantDataAttr ? JSON.parse(selectedVariantDataAttr) : null;
        var variantInStock = variantInStockDataAttr ? true : false;

        // invalid
        if (!selectedVariant) {
          this._showError(t("Sorry, that item variant is unavailable. Please select another variant."));






          return;
        }
        if (!variantInStock) {
          this._showError(t("Sorry, we do not have enough of that item available."));


          return;
        }

        variant = selectedVariant;
      }

      /*
       * Get quantity.
       */
      var qtyEl = btn.get('parentNode').siblings('.product-quantity-input').item(0);
      if (qtyEl) {
        qty = Y.one(qtyEl).one('input').get('value');

        if (!Y.Lang.isNumber(Number(qty))) {
          this._showError(t("Quantity must be a number."));


          return;
        }
      }

      /*
       * Actually add to cart.
       */

      // Track click add to cart/subscribe button on PDP
      if (Y.Squarespace.CartUtils._isProductDetailsPage()) {
        try {
          Y.Squarespace.CartUtils._trackAddToCart();
        } catch (error) {
          ErrorReporter.trackCaughtError('commerce-merch', 'trackAddToCart', error.stack);
        }
      }

      if (btn.hasClass('use-form')) {
        this._verifyItemInStock({
          itemId: itemId,
          variant: variant,
          inStockCb: Y.bind(function () {
            Y.Squarespace.CartUtils._openAdditionalFieldsForm(btn, function (additionalFields) {
              _checkoutRedirectHandler(btn, itemId, variant,
              qty, additionalFields, this._showError);
            }, this);
          }, this),
          outOfStockCb: Y.bind(function () {
            this._showError(t("Sorry, we do not have enough of that item available."));


          }, this) });

      } else {
        _checkoutRedirectHandler(btn, itemId, variant, qty, null, this._showError.bind(this));
      }
    },

    /**
     * Creates cart and goes to subscription checkout
     */
    _goToSubscriptionCheckout: function (btn, itemId, variant, qty, additionalFields, showError) {
      if (Y.Lang.isValue(qty) && qty % 1 !== 0) {
        showError(t("Quantity must be a whole number"));


        Y.Squarespace.CartUtils._setButtonStateIdle(btn);
        return;
      }

      Y.Data.post({
        url: '/api/3/commerce/cart/subscription',
        json: true,
        data: {
          itemId: itemId,
          sku: variant && variant.sku,
          qty: qty,
          additionalFields: Y.JSON.stringify(additionalFields || {}) },

        success: function (data) {
          Y.Squarespace.Commerce.goToSubscriptionPage(data.cartToken);
        },
        failure: function (data) {
          showError(data.message);
        } });

    },

    /**
     * Redirects the user to the gift card checkout page.
     *
     * @param {Event} e
     * @private
     */
    _purchaseGiftCard: function (e) {
      var btn = e.currentTarget;
      var itemId = btn.getAttribute('data-item-id');
      var selectedVariant = null;

      // the element that has variant data attached to it
      var variantDataEl = btn.get('parentNode').siblings('.product-variants').item(0);
      if (variantDataEl) {
        // multiple denominations, so pull the chosen one from the drop-down menu
        var unselectedOptions = JSON.parse(variantDataEl.getAttribute('data-unselected-options'));

        if (unselectedOptions.length) {
          // didn't select a denomination
          this._showGiftCardError(t("Select a denomination for the Gift Card"));


          return;
        }

        selectedVariant = JSON.parse(variantDataEl.getAttribute('data-selected-variant'));
      }

      Y.Squarespace.Commerce.goToGiftCardPage(itemId, selectedVariant);
    },

    /**
     * For an item without a workflow form, we verify that it's still in stock
     * when attempting to add to the cart. If it does have a form, we need to know
     * this prior to displaying the form, so a separate API call is needed.
     *
     * @method _verifyItemInStock
     * @private
     */
    _verifyItemInStock: function (cfg) {
      var shoppingCart = Y.Squarespace.Singletons.ShoppingCart;

      Y.Data.get({
        url: Y.Squarespace.API_ROOT + 'commerce/inventory/stock/',
        data: { itemId: cfg.itemId },
        success: function (response) {
          var inStock = Y.Array.some(response.results, function (result) {
            if (result.unlimited) {
              return true;
            }

            return result.qtyInStock > shoppingCart.totalForItem(cfg.itemId, cfg.variant);
          });

          if (inStock) {
            cfg.inStockCb();
          } else {
            cfg.outOfStockCb();
          }
        } });

    },

    _goToCheckoutOrAddToCart: function (btn, itemId, variant, qty, additionalFields, showError) {
      Y.Squarespace.CartUtils._setButtonStateAdding(btn);

      // validate where qty is defined
      if (Y.Lang.isValue(qty) && qty % 1 !== 0) {
        showError('Quantity must be a whole number');
        Y.Squarespace.CartUtils._setButtonStateIdle(btn);
        return;
      }

      // normal flow
      Y.Squarespace.Singletons.ShoppingCart.
      addEntry(itemId, variant, qty, additionalFields, function (error, successData) {

        if (error) {
          showError(error);
          Y.later(1000, this, function () {
            Y.Squarespace.CartUtils._setButtonStateIdle(btn);
          });
          return;
        }

        try {
          Y.Squarespace.CartUtils._trackAddToCartHelper(successData);
        } catch (e) {
          console.error(e);
          ErrorReporter.trackCaughtError('common', 'trackAddToCart issue', e.stack);
        }

        if (Y.Squarespace.Commerce.isExpressCheckout()) {
          Y.Squarespace.Commerce.goToCheckoutPage();
          return;
        }

        Y.later(1000, this, function () {
          if (!Y.Lang.isNull(btn._node)) {
            Y.Squarespace.CartUtils._setButtonStateAdded(btn);
            Y.later(2000, this, function () {
              Y.Squarespace.CartUtils._setButtonStateIdle(btn);
            });
          }
        });

      }, this);

    },

    _setButtonStateAdding: function (btn) {
      var btnInner = btn.one('.sqs-add-to-cart-button-inner');
      var btnWrapper = btn.get('parentNode');
      var viewCartLink = btnWrapper.one('.ProductItem-view-cart');
      // Our backend beta system does not work for this purpose because cart does not have Static.SQUARESPACE_CONTEXT
      // This is a feature owned by Josh Kills and he's okay with it turned off for now. - dduan, 7-23-18
      // var viewCartLinkEnabled = BetaFeaturesUtils.isFeatureEnabled(Flag.VIEW_CART_LINK);
      var viewCartLinkEnabled = false;

      Y.Squarespace.CartUtils._setButtonWidth(btn);

      btnInner.once('hidden', function () {
        btnInner.empty();

        btn.addClass('cart-adding');

        var buttonLabel = t("Adding\u2026");


        btnInner.append('<div class="status-text">' + buttonLabel + '</div>');
        if (viewCartLinkEnabled && !viewCartLink) {
          var cartLinkLabel = t("View Cart");


          btnWrapper.append('<div class="ProductItem-view-cart" ' +
          'style="display: none;"><a class="sqs-add-to-cart-button" href="/cart">' + cartLinkLabel + '</a></div>');
        }

        btnInner.show();
      }, this);

      btnInner.hide();
    },

    _setButtonStateAdded: function (btn) {
      var btnInner = btn.one('.sqs-add-to-cart-button-inner');

      btnInner.empty();
      var buttonLabel = t("Added!");


      btn.addClass('cart-added');
      btnInner.append('<div class="status-text">' + buttonLabel + '</div>');
    },

    _setButtonStateIdle: function (btn) {
      var btnInner = btn.one('.sqs-add-to-cart-button-inner');

      btnInner.once('hidden', function () {
        btnInner.empty();
        btn.removeClass('cart-adding');
        btnInner.setContent(btn.getData('original-label'));

        btnInner.show();
      }, this);

      btnInner.hide();
    },

    _openAdditionalFieldsForm: function (btn, callback, ctx) {
      if (additionalFieldsModalLightbox) {
        additionalFieldsModalLightbox.destroy(); //force re-render
      }

      additionalFieldsModalLightbox = new Y.Squarespace.Widgets.ModalLightbox({
        render: Y.one('body'),
        hasExtraBottomMargin: !!Y.UA.ios });


      var formJson = Y.JSON.parse(btn.getData('form'));
      var formWidget = new Y.Squarespace.Widgets.AsyncForm({
        form: formJson,
        formTemplate: additionalFieldsFormTemplate,
        formSubmitButtonText: btn.getData('original-label'),
        formName: formJson.name,
        showTitle: true });


      formWidget.on('submission', function (e) {
        if (callback) {
          callback.call(ctx || this, e.data);
        }

        additionalFieldsModalLightbox.close();
      }, this);

      additionalFieldsModalLightbox.set('content', formWidget);

      additionalFieldsModalLightbox.once('close', function () {
        formWidget.destroy();
      }, this);

      additionalFieldsModalLightbox.open();
    },

    _getAdditionalFieldsFormTemplateSchema: function (callback, ctx) {
      Y.Data.get({
        url: '/api/template/GetTemplateSchema',
        data: {
          type: BlockType.FORM },

        success: function (data) {
          callback.call(ctx || this, data);
        } },
      this);
    },

    _trackAddToCartHelper: function (itemDetails) {
      var sku = itemDetails.chosenVariant ? itemDetails.chosenVariant.sku : null;
      var attributes = itemDetails.chosenVariant ? itemDetails.chosenVariant.attributes : null;

      trackEvent.trackAddToCart({
        value: (itemDetails.subTotal / 100).toFixed(2),
        currency: StoreCurrencyManager.getStoreCurrency(),
        'content_name': itemDetails.title,
        'content_type': 'product',
        'content_ids': sku,
        contents: [{
          id: sku,
          quantity: itemDetails.quantity,
          'item_price': (itemDetails.purchasePriceCents / 100).toFixed(2),
          attributes: attributes }] });


    },

    _isProductDetailsPage: function () {
      var isContentItemPage =
      Static.SQUARESPACE_CONTEXT.pageType === PageTypes.CONTENT_ITEM;

      var isProduct = !!Static.SQUARESPACE_CONTEXT.product;

      return isContentItemPage && isProduct;
    },

    _trackAddToCart: function () {

      if (!isFeatureEnabled(Flag.COMMERCE_SITE_VISITOR_METRICS)) {
        return;
      }

      var website = Static.SQUARESPACE_CONTEXT.website;
      var product = Static.SQUARESPACE_CONTEXT.product;
      var item = Static.SQUARESPACE_CONTEXT.item;
      var collection = Static.SQUARESPACE_CONTEXT.collection;
      var cart = Y.Squarespace.Singletons.ShoppingCart;

      if (!website || !product || !item || !collection || !cart) {
        return;
      }

      SiteVisitorTracking.trackAddToCart({
        website_id: website.id,
        page_id: item.id,
        cart_id: cart.get('id'),
        product_page: 'pdp',
        product: {
          item_id: item.id,
          item_type: product.productType.toString(),
          item_title: item.title,
          item_collection_id: collection.id,
          item_collection_name: collection.title } });


    } };


}, '1.0', {
  requires: [
  'base',
  'json',
  'node',
  'squarespace-animations',
  'squarespace-async-form',
  'squarespace-commerce-utils',
  'squarespace-modal-lightbox',
  'squarespace-models-shopping-cart',
  'squarespace-spinner',
  'squarespace-widgets-alert'] });