// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/product-quick-view.css';
/**
 * Renderer for Product Quick View
 *
 * @module squarespace-product-quick-view
 */

YUI.add('squarespace-product-quick-view', function (Y) {

  var buttonSelector = '.sqs-product-quick-view-button[data-id]';
  var quickViewEventHandlers = [];
  var quickViewModules = [];

  Y.config.win.Squarespace.onInitialize(Y, function() {
    if (Y.UA.mobile) {
      return; // support for touch devices forthcoming
    }

    var lightbox = null;
    var products = {};

    var renderModal = function(element, ev) {

      var id = element.getData('id');
      var group = element.getData('group') || 'ungrouped';

      // store an array of product ids for each group on the page
      if (!products[group]) {
        products[group] = [];
        Y.all(buttonSelector).each(function(button) {
          if (group === button.getData('group') || (group === 'ungrouped' && !button.getData('group'))) {
            products[group].push(button.getData('id'));
          }
        });
      }

      // spawn the lightbox
      if (lightbox) {
        if (lightbox.get('currentId') === id && lightbox.get('currentGroup') === group) {
          lightbox.open();
        } else {
          // syncUI and open don't need to be called
          // here because the lightbox listens for
          // changes to currentId or currentGroup and
          // syncs and opens itself.
          lightbox.set('currentId', id);
          lightbox.set('currentGroup', group);
        }
      } else {
        lightbox = new Y.Squarespace.Widgets.Products.QuickView({
          currentId: id,
          currentGroup: group,
          products: products,
          render: true
        });

        quickViewModules.push(lightbox);
      }
      if (ev) {
        ev.halt(true);
      }

    };

    var clickHandler = Y.one('body').delegate('click', function(e) {
      renderModal(e.currentTarget, e);
    }, buttonSelector);

    Y.one('body').delegate('keydown', function(e) {
      // Standard button behavior (which we have to restore here since
      // the widget is not really a button but a div or a span)
      // is to react to Enter (keyCode == 13) and Space (keyCode == 32) presses.
      if (e.keyCode === 13 || e.keyCode === 32) {
        renderModal(e.currentTarget, e);
      }
    }, buttonSelector);

    // Render the lightbox if lightbox-related tweaks are changed so users can see what's going on.
    var tweakHandler = Y.Global.on('tweak:change', function(field) {

      var tweak = field.getName();
      var isChangedLightboxTweak = tweak.indexOf('tweak-product-quick-view-lightbox-') > -1;
      var isChangedItemGalleryTweak = tweak.indexOf('tweak-product-item-gallery-') > -1;
      var isProductItemPage = Y.one('.collection-type-products.view-item');

      if (isChangedLightboxTweak || (isChangedItemGalleryTweak && !isProductItemPage)) {

        if (!lightbox) {
          var firstProduct = Y.one(buttonSelector);
          renderModal(firstProduct, null);
        } else if (!lightbox.get('isLightboxOpen')) {
          lightbox.open();
        }

      } else if (lightbox) {
        lightbox.get('lightbox').close();
      }

    });


    quickViewEventHandlers.push(clickHandler, tweakHandler);

  });


  Y.config.win.Squarespace.onDestroy(Y, function() {
    quickViewEventHandlers.forEach(function(handler) {
      handler.detach();
    });

    quickViewModules.forEach(function(module) {
      module.destroy();

      // Lightbox destroy logic is here instead of in the product quick view
      // destructor because putting it in the destructor removes the host node,
      // causing widget base to error out.
      module.get('lightbox').destroy(true);
    });

    quickViewEventHandlers.length = 0;
    quickViewModules.length = 0;
  });

}, '1.0', {
  requires: [
    'squarespace-widgets-products-quick-view'
  ]
});
