"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trackVisitPDP = exports.trackViewCart = exports.trackReviewsVitalsAnchorLink = exports.trackReviewsTab = exports.trackRemoveItemFromCart = exports.trackAddToCart = void 0;

var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread"));

var _v = require("@sqs/track-events/v2");

var _cookieCutter = _interopRequireDefault(require("@sqs/cookie-cutter"));

var _EnvUtils = require("./EnvUtils");

var _commerceConstants = require("@sqs/commerce-constants");

var _SiteUserAccountInfoAccessor = _interopRequireDefault(require("./SiteUserAccountInfoAccessor"));

var eventClient = new _v.SiteVisitorClient({
  customSchemaName: 'CommerceShopping',
  sourceEnvironment: (0, _EnvUtils.isInternal)() ? _v.SourceEnvironment.STAGING : _v.SourceEnvironment.PROD
}, {
  event_source: 'web',
  event_owner_team: 'commerce_merchandising',
  product_area: 'commerce_shopping'
});

var trackVisitPDP = function trackVisitPDP(_ref) {
  var website_id = _ref.website_id,
      page_id = _ref.page_id,
      product_page = _ref.product_page,
      pdp_layout = _ref.pdp_layout,
      product = _ref.product;
  eventClient.track((0, _objectSpread2.default)({
    event_name: 'CommerceShopping_VisitPDP',
    actor: 'site_user',
    action: 'view',
    object_type: 'product',
    email_derived_user_id: _SiteUserAccountInfoAccessor.default.getSiteUserId(),
    logged_in: _SiteUserAccountInfoAccessor.default.isAuthenticated(),
    // include stale session
    website_id: website_id,
    page_id: page_id,
    product_page: product_page,
    pdp_layout: pdp_layout,
    end_user_session_id: _cookieCutter.default.get(_commerceConstants.CRUMB_COOKIE),
    ss_cvr: _cookieCutter.default.get(_commerceConstants.CENSUS_COOKIE)
  }, product));
};

exports.trackVisitPDP = trackVisitPDP;

var trackReviewsVitalsAnchorLink = function trackReviewsVitalsAnchorLink(params) {
  eventClient.track((0, _objectSpread2.default)({
    event_name: 'CommerceShopping_ReviewsVitalsAnchorLink',
    actor: 'site_user',
    action: 'click',
    object_type: 'link',
    email_derived_user_id: _SiteUserAccountInfoAccessor.default.getSiteUserId(),
    ss_cvr: _cookieCutter.default.get(_commerceConstants.CENSUS_COOKIE),
    logged_in: _SiteUserAccountInfoAccessor.default.isAuthenticated(),
    product_page: 'pdp',
    end_user_session_id: _cookieCutter.default.get(_commerceConstants.CRUMB_COOKIE)
  }, params));
};

exports.trackReviewsVitalsAnchorLink = trackReviewsVitalsAnchorLink;

var trackReviewsTab = function trackReviewsTab(_ref2) {
  var website_id = _ref2.website_id,
      item_id = _ref2.item_id,
      item_type = _ref2.item_type,
      item_title = _ref2.item_title,
      page_id = _ref2.page_id,
      item_category_ids = _ref2.item_category_ids,
      item_category_names = _ref2.item_category_names,
      item_collection_id = _ref2.item_collection_id,
      item_collection_name = _ref2.item_collection_name,
      pdp_layout = _ref2.pdp_layout,
      tabType = _ref2.tabType;
  eventClient.track({
    event_name: 'CommerceShopping_ReviewsTab',
    actor: 'site_user',
    action: 'click',
    object_type: 'tab',
    email_derived_user_id: _SiteUserAccountInfoAccessor.default.getSiteUserId(),
    ss_cvr: _cookieCutter.default.get(_commerceConstants.CENSUS_COOKIE),
    logged_in: _SiteUserAccountInfoAccessor.default.isAuthenticated(),
    website_id: website_id,
    item_id: item_id,
    item_type: item_type,
    item_title: item_title,
    page_id: page_id,
    item_category_ids: item_category_ids,
    item_category_names: item_category_names,
    item_collection_id: item_collection_id,
    item_collection_name: item_collection_name,
    product_page: 'pdp',
    pdp_layout: pdp_layout,
    end_user_session_id: _cookieCutter.default.get(_commerceConstants.CRUMB_COOKIE),
    object_identifier: tabType
  });
};

exports.trackReviewsTab = trackReviewsTab;

var trackAddToCart = function trackAddToCart(_ref3) {
  var website_id = _ref3.website_id,
      page_id = _ref3.page_id,
      cart_id = _ref3.cart_id,
      product_page = _ref3.product_page,
      pdp_layout = _ref3.pdp_layout,
      product = _ref3.product;
  eventClient.track((0, _objectSpread2.default)({
    event_name: 'CommerceShopping_AddToCart',
    actor: 'site_user',
    action: 'add',
    object_type: 'product',
    email_derived_user_id: _SiteUserAccountInfoAccessor.default.getSiteUserId(),
    logged_in: _SiteUserAccountInfoAccessor.default.isAuthenticated(),
    // include stale session
    website_id: website_id,
    page_id: page_id,
    product_page: product_page,
    pdp_layout: pdp_layout,
    cart_id: cart_id,
    end_user_session_id: _cookieCutter.default.get(_commerceConstants.CRUMB_COOKIE),
    ss_cvr: _cookieCutter.default.get(_commerceConstants.CENSUS_COOKIE)
  }, product));
};

exports.trackAddToCart = trackAddToCart;

var trackViewCart = function trackViewCart(_ref4) {
  var website_id = _ref4.website_id,
      cart_id = _ref4.cart_id,
      num_items_in_cart = _ref4.num_items_in_cart,
      product_types_list = _ref4.product_types_list,
      cart_value = _ref4.cart_value,
      purchase_type_list = _ref4.purchase_type_list,
      currency_code = _ref4.currency_code;
  eventClient.track({
    event_name: 'CommerceShopping_ViewCart',
    actor: 'site_user',
    action: 'view',
    object_type: 'cart',
    email_derived_user_id: _SiteUserAccountInfoAccessor.default.getSiteUserId(),
    logged_in: _SiteUserAccountInfoAccessor.default.isAuthenticated(),
    // include stale session
    website_id: website_id,
    cart_id: cart_id,
    num_items_in_cart: num_items_in_cart,
    product_types_list: product_types_list,
    purchase_type_list: purchase_type_list,
    cart_value: cart_value,
    currency_code: currency_code,
    end_user_session_id: _cookieCutter.default.get(_commerceConstants.CRUMB_COOKIE),
    ss_cvr: _cookieCutter.default.get(_commerceConstants.CENSUS_COOKIE)
  });
};

exports.trackViewCart = trackViewCart;

var trackRemoveItemFromCart = function trackRemoveItemFromCart(_ref5) {
  var website_id = _ref5.website_id,
      item_id = _ref5.item_id,
      item_type = _ref5.item_type,
      item_title = _ref5.item_title,
      item_variant_object = _ref5.item_variant_object,
      item_price = _ref5.item_price,
      currency_code = _ref5.currency_code,
      item_quantity = _ref5.item_quantity;
  eventClient.track({
    event_name: 'CommerceShopping_RemoveFromCart',
    actor: 'site_user',
    action: 'view',
    object_type: 'cart',
    email_derived_user_id: _SiteUserAccountInfoAccessor.default.getSiteUserId(),
    logged_in: _SiteUserAccountInfoAccessor.default.isAuthenticated(),
    // include stale session
    website_id: website_id,
    item_id: item_id,
    item_type: item_type,
    item_title: item_title,
    item_variant_object: item_variant_object,
    item_price: item_price,
    currency_code: currency_code,
    item_quantity: item_quantity,
    end_user_session_id: _cookieCutter.default.get(_commerceConstants.CRUMB_COOKIE),
    ss_cvr: _cookieCutter.default.get(_commerceConstants.CENSUS_COOKIE)
  });
};

exports.trackRemoveItemFromCart = trackRemoveItemFromCart;