"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.t = exports.pluralize = exports.getCurrencySymbol = exports.getCountries = exports.formatRelativeTime = exports.formatQuantity = exports.formatNumber = exports.formatMoney = exports.formatIcuMessage = exports.formatDateTime = exports.formatCurrencyToParts = exports.formatCurrency = void 0;

var _icuMessageFormatter = _interopRequireDefault(require("@sqs/i18n-ui/lib/icu-message-formatter"));

var _index = require("./index");

var i18nInstance = (0, _index.getI18nInstance)();
var messageFormatterInstance = new _icuMessageFormatter.default(i18nInstance);
var formatNumber = i18nInstance.formatNumber,
    formatCurrency = i18nInstance.formatCurrency,
    formatCurrencyToParts = i18nInstance.formatCurrencyToParts,
    getCountries = i18nInstance.getCountries,
    getCurrencySymbol = i18nInstance.getCurrencySymbol,
    formatQuantity = i18nInstance.formatQuantity,
    formatDateTime = i18nInstance.formatDateTime,
    formatRelativeTime = i18nInstance.formatRelativeTime,
    t = i18nInstance.translate,
    pluralize = i18nInstance.pluralize,
    formatMoney = i18nInstance.formatMoney;
exports.formatMoney = formatMoney;
exports.pluralize = pluralize;
exports.t = t;
exports.formatRelativeTime = formatRelativeTime;
exports.formatDateTime = formatDateTime;
exports.formatQuantity = formatQuantity;
exports.getCurrencySymbol = getCurrencySymbol;
exports.getCountries = getCountries;
exports.formatCurrencyToParts = formatCurrencyToParts;
exports.formatCurrency = formatCurrency;
exports.formatNumber = formatNumber;
var formatIcuMessage = messageFormatterInstance.formatIcuMessage;
exports.formatIcuMessage = formatIcuMessage;